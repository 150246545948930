
















































































































































































import { defineComponent } from '@vue/composition-api';
import { mapState } from 'vuex';
import { v1 as uuid } from 'uuid';
import DropdownOptionsOptmized from '@/views/cashFlow/predictedFlow/components/list/DropdownOptionsOptmized.vue';
import ERPRepository from '@/repositories/ERPRepository';
import CashFlowSimulationRepository from '@/repositories/CashFlowSimulationRepository';
import CashFlowActionRepository from '@/repositories/CashFlowActionRepository';
import CompanyGroupConfigurationRepository from '@/repositories/CompanyGroupConfigurationRepository';
import PredictedFlowHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowHelper';
import PredictedFlowTotalsHelper from '@/views/cashFlow/predictedFlow/helpers/PredictedFlowTotalsHelper';
import CurrencyHelper from '@/helpers/CurrencyHelper';
import PeriodicityEnum from '@/views/cashFlow/predictedFlow/enums/PeriodicityEnum';
import ReportTypesEnum from '@/views/cashFlow/predictedFlow/enums/ReportTypesEnum';
import ActionsWithoutReloadEnum from '@/views/cashFlow/predictedFlow/enums/ActionsWithoutReloadEnum';
import CashFlowTablesEnum from '@/views/cashFlowGroupers/mainGrouper/enums/CashFlowTablesEnum';
import ERPEventsEnum from '@/domain/enums/ERPEventsEnum';
import AccountActionsEnum from '@/repositories/parameters/AccountLogs/enums/AccountActionsEnum';
import CashFlowList from '@/domain/models/cashFlow/CashFlowList';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import IPredictedFlowGrouperList from '@/views/cashFlow/predictedFlow/interfaces/groupers/IPredictedFlowGrouperList';
import IPeriodicityRangeDates from '@/views/cashFlow/predictedFlow/interfaces/IPeriodicityRangeDates';
import IMainGrouper from '@/views/cashFlow/predictedFlow/interfaces/groupers/IMainGrouper';
import IDefaultGrouperFields from '@/views/cashFlow/predictedFlow/interfaces/groupers/IDefaultGrouperFields';
import ISecondGrouper from '@/views/cashFlow/predictedFlow/interfaces/groupers/ISecondGrouper';
import IThirdGrouper from '@/views/cashFlow/predictedFlow/interfaces/groupers/IThirdGrouper';
import IFourthGrouperItems from '@/views/cashFlow/predictedFlow/interfaces/groupers/IFourthGrouperItems';
import IFirstGrouper from '@/views/cashFlow/predictedFlow/interfaces/groupers/IFirstGrouper';
import IChangeDateExternalERPRequestParams from '@/views/cashFlow/predictedFlow/interfaces/actions/IChangeDateExternalERPRequestParams';
import IApproveDisapproveRequestList from '@/views/cashFlow/predictedFlow/interfaces/actions/IApproveDisapproveRequestList';
import IUpsertSimulationFiltersRequest from '@/views/cashFlow/predictedFlow/interfaces/IUpsertSimulationFiltersRequest';
import IReloadScreenActions from '@/views/cashFlow/predictedFlow/interfaces/reloadScreenActions/IReloadScreenActions';
import ITotalsData from '@/views/cashFlow/predictedFlow/interfaces/groupers/ITotalsData';
import AccountsLogRepository from '@/repositories/AccountsLogRepository';
import PredictedFlowActionsEnum from '../enums/PredictedFlowActionsEnum';

export default defineComponent({
  components: {
    DropdownOptionsOptmized,
  },
  props: {
    isLoading: {
      required: true,
      type: Boolean,
    },
    cashFlowListData: {
      required: false,
      type: CashFlowList,
    },
    selectedPeriodicity: {
      required: false,
      type: [String as () => PeriodicityEnum],
    },
    reportType: {
      required: true,
      type: [String as () => ReportTypesEnum, Boolean],
    },
  },
  watch: {
    cashFlowListData(newValue: CashFlowList): void {
      this.generateHeadersAndDataToCashFlowTablePatern(newValue);
    },
    isEventSend(isTriggered: boolean) {
      if (isTriggered) {
        this.updateListValuesAndTotals(this.eventData);
      }
    },
  },
  computed: {
    ...mapState({
      companyGroupId: (state: any) => Number(state.authentication.user.company_group_id),
      isEventSend: (state: any): boolean => state.predicted_flow.isEventSend,
      eventData: (state: any): IReloadScreenActions => state.predicted_flow.eventData,
    }),
    companyId(): number {
      return this.$store.getters['authentication/companyIds'][0];
    },
    isDarkModeClass(): string {
      return this.$vuetify.theme.dark ? 'dark' : '';
    },
    headerClass(): string {
      return `predicted-flow-header${this.isDarkModeClass}`;
    },
    periodicityTextHeaderClass(): string {
      return `periodicity-text ${this.isDarkModeClass}`;
    },
    totalFooterClass(): string {
      return `predicted-flow-footer ${this.isDarkModeClass}`;
    },
    hasOnlyOnePeriodicity(): boolean {
      return this.cashFlowListData?.periodicityDates.length === 1;
    },
  },
  data() {
    const erpRepository: ERPRepository = new ERPRepository();
    const cashFlowSimulationRepository:
      CashFlowSimulationRepository = new CashFlowSimulationRepository();
    const cashFlowActionRepository:
      CashFlowActionRepository = new CashFlowActionRepository();
    const accountsLogRepository: AccountsLogRepository = new AccountsLogRepository();
    const companyGroupConfigurationRepository:
      CompanyGroupConfigurationRepository = new CompanyGroupConfigurationRepository();

    const totalsRow: ITotalsData[] = [];

    const headers: IVDataTableHeader[] = [];
    const destructuredBankBalance: IPredictedFlowGrouperList[] = [];

    const draggableItem: {listIndex: number|null; totalIndex: number|null} = {
      listIndex: null,
      totalIndex: null,
    };

    return {
      totalsRow,
      destructuredBankBalance,
      headers,
      draggableItem,
      erpRepository,
      cashFlowSimulationRepository,
      cashFlowActionRepository,
      accountsLogRepository,
      companyGroupConfigurationRepository,
    };
  },
  created() {
    if (this.cashFlowListData) {
      this.generateHeadersAndDataToCashFlowTablePatern(this.cashFlowListData);

      document.addEventListener('click', this.addEventListenerClick);

      this.getCompanyGroupConfigurations();
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.addEventListenerClick);
  },
  methods: {
    addEventListenerClick(event: any): void {
      if (
        event.target
        && event.target instanceof HTMLElement
        && event.target.classList.contains('approve-button')
      ) {
        const { id, totalIndex } = event.target.dataset;

        const itemIndex = this.destructuredBankBalance
          .findIndex((item) => item.identifier === id);
        const parsedTotalIndex = Number(totalIndex);

        this.handleApproveDisapprove(
          itemIndex,
          parsedTotalIndex,
          this.destructuredBankBalance[itemIndex],
        );
      }
    },
    setApproveButtonShow(identifier: string, totalIndex: number, isReleased: boolean): void {
      const elementByData = document.querySelector(`[data-id='${identifier}'][data-total-index='${totalIndex}']`);
      if (!elementByData) {
        return;
      }

      if (isReleased) {
        elementByData.classList.remove('approve-disapprove-button-pointer-appear');
      } else {
        elementByData.classList.add('approve-disapprove-button-pointer-appear');
      }
    },
    mustShowRow(item: IPredictedFlowGrouperList): boolean {
      let showshow = false;

      if (item.show) {
        showshow = item.showFromScroll;
      } else {
        showshow = false;
      }

      return showshow;
    },
    cashflowArrayDestructor(
      grouper: IMainGrouper[],
    ): IPredictedFlowGrouperList[] {
      const destructuredArray: IPredictedFlowGrouperList[] = [];

      const destructor = (groups: IDefaultGrouperFields[], level: number) => {
        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];

          let mustBeAllExpanded = true;
          let mustShow = true;

          if (
            this.reportType
            && this.reportType === ReportTypesEnum.SYNTHETIC
          ) {
            mustBeAllExpanded = false;
            mustShow = level === 0;
          }

          const formattedObject: IPredictedFlowGrouperList = {
            name: group.name,
            totals: group.totals,
            grouperType: group.grouperType,
            show: mustShow,
            showFromScroll: mustShow,
            isSimulation: false,
            level,
            hasChilds: false,
            identifier: uuid(),
            parentIdentifier: undefined,
          };

          if (level < 4) {
            formattedObject.expanded = mustBeAllExpanded;
          }

          if ((level === 2 && group.grouperType)) {
            const { hasSimulation, grouperItemId } = group as ISecondGrouper;

            formattedObject.grouperConfigs = {
              hasSimulation,
              grouperItemId,
              editableValue: false,
            };
          }

          if (level === 3) {
            const typedGroup = group as IThirdGrouper;

            formattedObject.isSimulation = typedGroup.isSimulation;
            formattedObject.simulationId = typedGroup.simulationId;
            formattedObject.grouperConfigs = {
              hasSimulation: false,
              editableValue: typedGroup.editableValue!,
              grouperItemId: typedGroup.grouperItemId,
            };
          }

          if (
            level === 4
            || (group.grouperType === CashFlowTablesEnum.BANKS && level === 3)
          ) {
            const typedGroup = group as IThirdGrouper | IFourthGrouperItems;

            if (!formattedObject.grouperConfigs) {
              formattedObject.grouperConfigs = {
                editableValue: !!(typedGroup.editableValue),
                hasSimulation: false,
              };
            }

            formattedObject.totals = typedGroup.totals;
            formattedObject.itemData = {
              id: typedGroup.id!,
              idCustomer: typedGroup.idCustomer!,
              keyId: typedGroup.keyId!,
              customerIdCustomer: typedGroup.customerIdCustomer!,
              date: typedGroup.date!,
              companyId: typedGroup.companyId!,
              type: typedGroup.type!,
              supplierId: typedGroup.supplierId,
            };
          }

          if (level === 4) {
            const typedGroup = group as IFourthGrouperItems;

            if (formattedObject.itemData) {
              formattedObject.itemData.increase = typedGroup.increase;
              formattedObject.itemData.decrease = typedGroup.decrease;
              formattedObject.itemData.originalTotal = typedGroup.originalTotal;
            }
          }

          if (level > 0) {
            formattedObject.parentIdentifier = this.getParentGroupIdentifierFromChildIndex(
              destructuredArray.length - 1,
              group.grouperType,
              level,
              destructuredArray,
            );
          }

          const typedGroup = group as
            | IMainGrouper
            | IFirstGrouper
            | ISecondGrouper
            | IThirdGrouper;
          const levelsThatHaveCollapseButton = [
            0, 1, 2, ...(group.grouperType !== CashFlowTablesEnum.BANKS ? [3] : []),
          ];

          if (levelsThatHaveCollapseButton.includes(level) && typedGroup.items) {
            formattedObject.hasChilds = typedGroup.items.length > 0;
          }

          destructuredArray.push(formattedObject);

          if (typedGroup.items) {
            destructor(typedGroup.items, level + 1);
          }
        }
      };

      destructor(grouper, 0);

      return destructuredArray;
    },
    getParentGroupIdentifierFromChildIndex(
      registerIndex: number,
      grouperType: CashFlowTablesEnum,
      level: number,
      destructuredArray: IPredictedFlowGrouperList[],
    ): string|undefined {
      for (let i = registerIndex; i >= 0; i -= 1) {
        const destructuredRegister = destructuredArray[i];

        if (
          destructuredRegister.grouperType === grouperType
          && destructuredRegister.level === level - 1
        ) {
          return destructuredRegister.identifier;
        }
      }

      return undefined;
    },
    async getCompanyGroupConfigurations(): Promise<void> {
      try {
        this.$dialog.startLoading();

        const companyGroupConfiguration = await this.companyGroupConfigurationRepository
          .getCompanyGroupConfigurationByCompanyGroupId(this.companyGroupId);

        this.shouldConsiderInitialBalanceOnPredictedFlow = companyGroupConfiguration
          .shouldConsiderInitialBalanceOnPredictedFlow;
      } catch (error) {
        this.$notification.error('Houve um problema ao requisitar as configurações de grupo de empresa.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async updateListValuesAndTotals(item: IReloadScreenActions): Promise<void> {
      if (item.action === ActionsWithoutReloadEnum.change_comment) {
        const totalsArray = [...this.destructuredBankBalance[item.itemIndex].totals];

        totalsArray[item.data.totalIndex].comment = item.data.value;
        this.destructuredBankBalance[item.itemIndex].totals = totalsArray;

        this.$store.commit('predicted_flow/emptyEvent');

        return;
      }

      if (item.action === ActionsWithoutReloadEnum.change_value) {
        const totalsArray = [...this.destructuredBankBalance[item.itemIndex].totals];

        totalsArray[item.data.totalIndex].value = item.data.value;
        this.destructuredBankBalance[item.itemIndex].totals = totalsArray;
        this.destructuredBankBalance[item.itemIndex].itemData!.originalTotal = item.data.value;
      }

      if (item.action === ActionsWithoutReloadEnum.include_increase_decrease) {
        if (item.data.type === 'increase') {
          this.destructuredBankBalance[item.itemIndex].itemData!.increase = item.data.value;
        } else {
          this.destructuredBankBalance[item.itemIndex].itemData!.decrease = item.data.value;
        }

        const {
          originalTotal,
          increase,
          decrease,
        } = this.destructuredBankBalance[item.itemIndex].itemData!;

        const totalsArray = [...this.destructuredBankBalance[item.itemIndex].totals];
        const calculatedNewTotal = (originalTotal! + increase!) - decrease!;

        totalsArray[item.data.totalIndex].value = calculatedNewTotal;
        this.destructuredBankBalance[item.itemIndex].totals = totalsArray;
      }

      if (item.action === ActionsWithoutReloadEnum.change_date) {
        const isNewDateBetweenFilterSomePeriodicities = PredictedFlowTotalsHelper
          .isDateBetweenSomeRange(
            item.data.value,
            this.selectedPeriodicity!,
            this.cashFlowListData!.periodicityDates,
          );

        if (!isNewDateBetweenFilterSomePeriodicities) {
          this.destructuredBankBalance.splice(item.itemIndex, 1);
        } else {
          this.destructuredBankBalance[item.itemIndex].itemData!.date = item.data.value;

          const totalsArray = [...this.destructuredBankBalance[item.itemIndex].totals];
          const newDateTotalsIndex = PredictedFlowTotalsHelper.getTotalsIndexBasedOnDate(
            item.data.value,
            this.selectedPeriodicity!,
            this.cashFlowListData!.periodicityDates,
          );

          totalsArray[newDateTotalsIndex].value = totalsArray[item.data.totalIndex].value;
          totalsArray[item.data.totalIndex].value = 0;
          this.destructuredBankBalance[item.itemIndex].totals = totalsArray;

          const isNewDateRegisterReleased = await this.getInternalRegisterOccurenceByDate(
            item.data.value,
            this.destructuredBankBalance[item.itemIndex].itemData!.keyId,
            this.destructuredBankBalance[item.itemIndex].grouperType,
          );

          this.destructuredBankBalance[item.itemIndex]
            .totals[newDateTotalsIndex].isReleased = isNewDateRegisterReleased;
        }
      }

      this.calculateTotals(this.destructuredBankBalance[item.itemIndex].grouperType);
      this.$store.commit('predicted_flow/emptyEvent');
    },
    calculateTotals(grouperType: CashFlowTablesEnum): void {
      const defaultTotalsArray: ITotalsData[] = new Array(this.cashFlowListData!.totals.length)
        .fill({ value: 0 });

      const grouperRegisterByLevelAndParentIndex = (
        level: number,
        grouperType: CashFlowTablesEnum,
      ) => {
        const filteredByLevel: IPredictedFlowGrouperList[] = [];

        for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
          const {
            level: registerLevel,
            grouperType: registerGrouperType,
            isSimulation,
          } = this.destructuredBankBalance[i];

          if (
            registerLevel === level
            && registerGrouperType === grouperType
            && !isSimulation
          ) {
            filteredByLevel.push(this.destructuredBankBalance[i]);
          }
        }

        for (let baseIndex = 0; baseIndex < filteredByLevel.length; baseIndex += 1) {
          const { totals, identifier } = filteredByLevel[baseIndex];

          const childTotals = this.destructuredBankBalance
            .filter(({ parentIdentifier }) => parentIdentifier === identifier)
            .map(({ totals }): ITotalsData[] => totals.map((totalData): ITotalsData => {
                if (Object.prototype.hasOwnProperty.call(totalData, 'isReleased')) {
                  return (
                    totalData.isReleased && totalData.value > 0 ? totalData : {
                      value: 0,
                    }
                  );
                }

                return totalData;
              }));

          const calculatedTotals = childTotals.reduce(
            (acc, row) => row.map((value, index) => ({ value: acc[index].value + value.value })),
            defaultTotalsArray,
          );

          for (let totalsIndex = 0; totalsIndex < totals.length; totalsIndex += 1) {
            totals[totalsIndex].value = calculatedTotals[totalsIndex].value;
          }
        }
      };

      const levelsOrderToCalculate = [2, 1, 0];

      if (grouperType !== CashFlowTablesEnum.BANKS) {
        levelsOrderToCalculate.unshift(3);
      }

      for (let i = 0; i < levelsOrderToCalculate.length; i += 1) {
        const orderLevel = levelsOrderToCalculate[i];

        grouperRegisterByLevelAndParentIndex(orderLevel, grouperType);
      }

      const accountsReceivableTotals = this
        .getTotalsFromSpecificGroup(CashFlowTablesEnum.ACCOUNTS_RECEIVABLE);
      const accountPayableTotals = this
        .getTotalsFromSpecificGroup(CashFlowTablesEnum.ACCOUNTS_PAYABLE);
      const bankLevelOneTotals = this
        .getTotalsFromSpecificGroup(CashFlowTablesEnum.BANKS);

      const bankLevelZero = this.destructuredBankBalance.find(
        (({ grouperType, level }) => grouperType === CashFlowTablesEnum.BANKS && level === 0),
      );

      if (!bankLevelZero) {
        return;
      }

      bankLevelZero.totals[0].value = this.shouldConsiderInitialBalanceOnPredictedFlow
        ? this.cashFlowListData!.previousValueBankBalance : 0;

      for (let i = 0; i < this.totalsRow.length; i += 1) {
        const actualTotalRow = this.totalsRow[i];

        bankLevelZero.totals[i].value += bankLevelOneTotals[i];

        const bankAddedValue = bankLevelZero.totals[i].value ?? 0;
        const accountsReceivableAddedValue = accountsReceivableTotals[i] ?? 0;
        const accountPayableAddedValue = accountPayableTotals[i] ?? 0;

        actualTotalRow.value = (
          (bankAddedValue + accountsReceivableAddedValue)
          - accountPayableAddedValue
        );

        if (bankLevelZero.totals[i + 1] !== undefined) {
          bankLevelZero.totals[i + 1].value = this.shouldConsiderInitialBalanceOnPredictedFlow
            ? actualTotalRow.value : 0;
        }
      }
    },
    getTotalsFromSpecificGroup(grouperName: CashFlowTablesEnum): number[] {
      const totalsAdded: number[] = [];

      for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
        const { level, totals, grouperType } = this.destructuredBankBalance[i];
        const levelToBeConsidered = grouperType === CashFlowTablesEnum.BANKS ? 1 : 0;

        if (grouperType === grouperName && level === levelToBeConsidered) {
          for (let totalsIndex = 0; totalsIndex < totals.length; totalsIndex += 1) {
            const { value } = totals[totalsIndex];

            totalsAdded[totalsIndex] = totalsAdded[totalsIndex] ?? 0 + value;
          }
        }
      }

      return totalsAdded;
    },
    getClassBasedOnLevel(grouper: IPredictedFlowGrouperList): string {
      let grouperClassType = 'predicted-flow-level-three';

      if (grouper.grouperType === CashFlowTablesEnum.BANKS) {
        grouperClassType = 'predicted-flow-level-four';
      }

      switch (grouper.level) {
        case 0:
          return `font-weight-medium predicted-flow-level-master ${this.isDarkModeClass}`;
        case 1:
          return `font-weight-medium predicted-flow-level-one ${this.isDarkModeClass}`;
        case 2:
          return `font-weight-medium predicted-flow-level-two ${this.isDarkModeClass}`;
        case 3:
          return `font-weight-medium ${grouperClassType} ${this.isDarkModeClass}`;
        case 4:
          return `predicted-flow-level-four ${this.isDarkModeClass}`;
        default:
          return '';
      }
    },
    makeHeadersBasedOnPeriodicity(
      periodicityDates: IPeriodicityRangeDates[],
    ): IVDataTableHeader[] {
      const periodicityArray: IVDataTableHeader[] = [
        {
          text: '',
          value: 'registers',
          width: '50%',
        },
      ];

      const formattedPeriodicityDatesHtmlText = periodicityDates.map(
        (periodicityDate, index): IVDataTableHeader => {
          let htmlText = '';

          if (this.selectedPeriodicity == PeriodicityEnum.DAILY) {
            htmlText = `${periodicityDate.date!} <br> ${periodicityDate.text!}`;
          }

          if (this.selectedPeriodicity == PeriodicityEnum.WEEKLY) {
            htmlText = `De ${periodicityDate.dateRange!.initial} <br> a ${
              periodicityDate.dateRange!.final
            }`;
          }

          if (this.selectedPeriodicity == PeriodicityEnum.MONTHLY) {
            htmlText = `${periodicityDate.date!} <br> ${periodicityDate.text!}`;
          }

          return {
            text: htmlText,
            value: `date-${index}`,
          };
        },
      );

      periodicityArray.push(...formattedPeriodicityDatesHtmlText);

      return periodicityArray;
    },
    generateHeadersAndDataToCashFlowTablePatern(
      cashFlowListData: CashFlowList,
    ): void {
      this.destructuredBankBalance = this.cashflowArrayDestructor(
        cashFlowListData.cashFlowItems,
      );
      this.headers = this.makeHeadersBasedOnPeriodicity(
        cashFlowListData.periodicityDates,
      );
      this.totalsRow = cashFlowListData.totals;

      this.$nextTick(() => this.setClassStatesToLinesAndTotals());
    },
    canDrag(item: IPredictedFlowGrouperList): boolean {
      return item.level === 4 && item.grouperType !== CashFlowTablesEnum.BANKS;
    },
    setClassStatesToLinesAndTotals() {
      for (let baseIndex = 0; baseIndex < this.destructuredBankBalance.length; baseIndex += 1) {
        const { show, totals, identifier } = this.destructuredBankBalance[baseIndex];

        const element = document.getElementById(`item_${baseIndex}`);

        if (element) {
          element.style.display = !show ? 'none' : '';
        }

        for (let totalIndex = 0; totalIndex < totals.length; totalIndex += 1) {
          this.setApproveButtonShow(
            identifier,
            totalIndex,
            totals[totalIndex].isReleased ?? false,
          );
        }
      }
    },
    mustShowActionOptionsOnGrouper(
      item: IPredictedFlowGrouperList,
      totalsData: ITotalsData,
    ): boolean {
      if (
        item.level === 0
        || (item.level === 3 && item.grouperType !== CashFlowTablesEnum.BANKS)
      ) {
        return false;
      }

      if (item.grouperType === CashFlowTablesEnum.BANKS && item.level < 3) {
        return false;
      }

      if (totalsData.value <= 0 && item.grouperType === CashFlowTablesEnum.BANKS) {
        return true;
      }

      return totalsData.value > 0;
    },
    collapse(itemIndex: number) {
      const selectedRegister = this.destructuredBankBalance[itemIndex];
      const registersAfterSelected = this.destructuredBankBalance.slice(
        itemIndex + 1,
      );

      selectedRegister.expanded = !selectedRegister.expanded;

      for (let i = 0; i < registersAfterSelected.length; i += 1) {
        const actualRegister = registersAfterSelected[i];

        if (selectedRegister.level >= actualRegister.level) {
          break;
        }

        const element = document.getElementById(`item_${itemIndex + i + 1}`);

        if (element) {
          element.style.display = !selectedRegister.expanded ? 'none' : '';
          actualRegister.show = selectedRegister.expanded;
        }
      }
    },
    getCollapseIcon(item: IPredictedFlowGrouperList): string {
      return item.expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-up';
    },
    toCurrency(value: any): string {
      return CurrencyHelper.toCurrency(value, { showCurrencySymbol: true });
    },
    mustShowSimulationButton(item: IPredictedFlowGrouperList): boolean {
      if (item.grouperConfigs?.hasSimulation) {
        return item.grouperConfigs.hasSimulation;
      }

      return false;
    },
    getApproveDisapproveRequestsArray(
      itemIndex: number,
      totalIndex: number,
      item: IPredictedFlowGrouperList,
    ): IApproveDisapproveRequestList[] {
      const registersToApproveDisapprove: IApproveDisapproveRequestList[] = [];

      if (
        item.level === 4
        || (item.level === 3 && item.grouperType === CashFlowTablesEnum.BANKS)
      ) {
        registersToApproveDisapprove.push({
          requestData: {
            date: item.totals[totalIndex].date!,
            key_id: item.itemData!.keyId,
            is_released: !item.totals[totalIndex].isReleased,
          },
          itemIndex,
          isReleased: !item.totals[totalIndex].isReleased,
        });
      }

      if (
        item.level === 3
        || (item.level === 2 && item.grouperType === CashFlowTablesEnum.BANKS)
      ) {
        for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
          const register = this.destructuredBankBalance[i];

          if (i > itemIndex) {
            if (register.level !== item.level + 1) {
              break;
            }

            const totalData = register.totals[totalIndex];

            if (totalData.value > 0) {
              registersToApproveDisapprove.push({
                requestData: {
                  date: totalData.date!,
                  key_id: register.itemData!.keyId,
                  is_released: !totalData.isReleased,
                },
                itemIndex: i,
                isReleased: !totalData.isReleased,
              });
            }
          }
        }
      }

      if (item.level === 2) {
        for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
          const register = this.destructuredBankBalance[i];

          if (i > itemIndex) {
            if (register.level === item.level) {
              break;
            }

            const totalData = register.totals[totalIndex];

            if (register.level === item.level + 2 && totalData.value > 0) {
              registersToApproveDisapprove.push({
                requestData: {
                  date: totalData.date!,
                  key_id: register.itemData!.keyId,
                  is_released: !totalData.isReleased,
                },
                itemIndex: i,
                isReleased: !totalData.isReleased,
              });
            }
          }
        }
      }

      if (item.level === 1) {
        for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
          const register = this.destructuredBankBalance[i];

          if (i > itemIndex) {
            if (register.level === item.level) {
              break;
            }

            const totalData = register.totals[totalIndex];
            const finalRegisterLevel = item.grouperType === CashFlowTablesEnum.BANKS ? 2 : 3;

            if (register.level === item.level + finalRegisterLevel && totalData.value > 0) {
              registersToApproveDisapprove.push({
                requestData: {
                  date: totalData.date!,
                  key_id: register.itemData!.keyId,
                  is_released: !totalData.isReleased,
                },
                itemIndex: i,
                isReleased: !totalData.isReleased,
              });
            }
          }
        }
      }

      if (item.level === 0) {
        for (let i = 0; i < this.destructuredBankBalance.length; i += 1) {
          const register = this.destructuredBankBalance[i];

          if (i > itemIndex) {
            if (register.level === item.level) {
              break;
            }

            const totalData = register.totals[totalIndex];
            const finalRegisterLevel = item.grouperType === CashFlowTablesEnum.BANKS ? 3 : 4;

            if (register.level === item.level + finalRegisterLevel && totalData.value > 0) {
              registersToApproveDisapprove.push({
                requestData: {
                  date: totalData.date!,
                  key_id: register.itemData!.keyId,
                  is_released: !totalData.isReleased,
                },
                itemIndex: i,
                isReleased: !totalData.isReleased,
              });
            }
          }
        }
      }

      return registersToApproveDisapprove;
    },
    async getInternalRegisterOccurenceByDate(
      newDate: string,
      keyId: string,
      grouperType: CashFlowTablesEnum,
    ): Promise<boolean> {
      try {
        this.$dialog.startLoading();

        const response = await this.erpRepository.getInternalTableData({
          _date: newDate,
          _field: 'liberado',
          _identifyValue: PredictedFlowHelper.getTableAliasByGrouperType(grouperType),
          _keyId: keyId,
        });

        this.$dialog.stopLoading();

        return Boolean(response.liberado);
      } catch (error) {
        this.$dialog.stopLoading();
        this.$notification.error('Houve um problema ao validar se a nova data possui registro aprovado.');

        return false;
      }
    },
    async handleApproveDisapprove(
      itemIndex: number,
      totalIndex: number,
      item: IPredictedFlowGrouperList,
    ): Promise<void> {
      const requestsDataArray = this
        .getApproveDisapproveRequestsArray(itemIndex, totalIndex, item);

      try {
        this.$dialog.startLoading();

        await this.cashFlowActionRepository.release(
          this.companyGroupId,
          item.grouperType,
          requestsDataArray.map(({ requestData }) => requestData),
        );

        for (let i = 0; i < requestsDataArray.length; i += 1) {
          const { itemIndex, isReleased } = requestsDataArray[i];

          this.destructuredBankBalance[itemIndex].totals[totalIndex].isReleased = isReleased;

          const {
            level,
            grouperType,
            identifier,
          } = this.destructuredBankBalance[itemIndex];

          if (
            level === 4
            || (level === 3 && grouperType === CashFlowTablesEnum.BANKS)
          ) {
            this.setApproveButtonShow(identifier, totalIndex, isReleased);
          }
        }

        this.calculateTotals(item.grouperType);

        this.$dialog.stopLoading();
        this.$notification.success('Registro aprovado/desaprovado com sucesso.');
      } catch (error) {
        this.$dialog.stopLoading();
        this.$notification.error('Houve um problema ao aprovar/desaprovar o registro.');
      }
    },
    handleUpdateComment(
      item: IPredictedFlowGrouperList,
      itemIndex: number,
      totalIndex: number,
    ): void {
      this.handleButtonOptionSelected(
        PredictedFlowActionsEnum.include_comment,
        item,
        itemIndex,
        totalIndex,
      );
    },
    handleButtonOptionSelected(
      event: PredictedFlowActionsEnum,
      item: IPredictedFlowGrouperList,
      itemIndex: number,
      totalIndex: number,
    ): void {
      let periodicityCorrespondentDate: undefined | string;

      if (this.selectedPeriodicity === PeriodicityEnum.DAILY) {
        periodicityCorrespondentDate = this.cashFlowListData?.periodicityDates[totalIndex]
        .dateUnformatted!;
      }

      this.$emit('action', {
        event,
        item,
        periodicityCorrespondentDate,
        itemIndex,
        totalIndex,
      });
    },
    handleAddSimulation(index: number, item: IPredictedFlowGrouperList): void {
      this.destructuredBankBalance.splice(index + 1, 0, {
        name: '',
        totals: JSON.parse(
          JSON.stringify(
            Array.from({ length: item.totals.length }).fill({ value: 0 }),
          ),
        ),
        grouperType: item.grouperType,
        grouperConfigs: item.grouperConfigs,
        itemData: item.itemData,
        isSimulation: true,
        showFromScroll: true,
        show: true,
        level: item.level + 1,
        hasChilds: false,
        identifier: uuid(),
      });

      const insertedSimulation = this.destructuredBankBalance[index + 1];

      insertedSimulation.parentIdentifier = this
        .getParentGroupIdentifierFromChildIndex(
          index + 1,
          insertedSimulation.grouperType,
          insertedSimulation.level,
          this.destructuredBankBalance,
        );

      this.$nextTick(() => this.setClassStatesToLinesAndTotals());
    },
    handleEditTotalSimulation(
      event: number,
      itemIndex: number,
      totalIndex: number,
    ): void {
      this.destructuredBankBalance[itemIndex].totals[totalIndex].value = event;
    },
    async handleUpsertSimulation(index: number): Promise<void> {
      const simulation = this.destructuredBankBalance[index];

      if (simulation.name === '' || simulation.name === null) {
        this.$notification.error('O campo de nome não está prenchido');

        return;
      }

      try {
        this.$dialog.startLoading();

        const formattedTotals = simulation.totals.map(({ value }, index) => {
          const object: Record<string, number> = {};
          const fieldName: string = this.cashFlowListData?.periodicityDates[index].dateUnformatted!;

          object[fieldName] = value;

          return object;
        });

        const arrayRequest: IUpsertSimulationFiltersRequest[] = [
          { description: simulation.name },
          { grouper_iten_id: simulation.grouperConfigs?.grouperItemId },
          { company_id: this.companyId },
          { itens: formattedTotals },
        ];

        if (simulation.simulationId) {
          await this.cashFlowSimulationRepository.updateSimulation([
            ...arrayRequest,
            { simulation_id: simulation.simulationId },
          ]);
        } else {
          const insertedId = await this.cashFlowSimulationRepository.saveSimulation(arrayRequest);
          simulation.simulationId = insertedId;
        }

        this.calculateTotals(simulation.grouperType);
      } catch (error) {
        this.$notification.error('Houve um problema ao salvar a simulação.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async handleRemoveSimulation(index: number): Promise<void> {
      const simulation = this.destructuredBankBalance[index];

      if (!simulation.simulationId) {
        this.destructuredBankBalance.splice(index, 1);

        return;
      }

      try {
        this.$dialog.startLoading();

        await this.cashFlowSimulationRepository.removeSimulation(simulation.simulationId);
        this.destructuredBankBalance.splice(index, 1);

        this.calculateTotals(simulation.grouperType);
        this.$nextTick(() => this.setClassStatesToLinesAndTotals());
      } catch (error) {
        this.$notification.error('Houve um problema ao remover a simulação.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async dragStart(event: any) {
      if (!event.target) return;

      this.draggableItem.totalIndex = event.target.getAttribute('data-total-index');
      this.draggableItem.listIndex = event.target.getAttribute('data-index');
    },
    async dropItem(event: any) {
      const indexesAreDefined = event.target?.getAttribute('data-index')
        && event.target?.getAttribute('data-total-index');

      if (!indexesAreDefined) {
        this.draggableItem.listIndex = null;
        this.draggableItem.totalIndex = null;

        return;
      }

      const droppedItemIndex = event.target!.getAttribute('data-index');
      const droppedTotalIndex = event.target!.getAttribute('data-total-index');

      if (droppedItemIndex !== this.draggableItem.listIndex) {
        return;
      }

      if (droppedTotalIndex === this.draggableItem.totalIndex) {
        return;
      }

      if (this.draggableItem.listIndex && this.draggableItem.totalIndex) {
        const totalDragged = this.destructuredBankBalance[this.draggableItem.listIndex!];

        await this.changeDate(
          totalDragged,
          this.cashFlowListData?.periodicityDates[droppedTotalIndex].dateUnformatted!,
          this.draggableItem.listIndex,
          this.draggableItem.totalIndex,
        );

        this.draggableItem.listIndex = null;
        this.draggableItem.totalIndex = null;
      }
    },
    async changeDate(
      register: IPredictedFlowGrouperList,
      date: string,
      itemIndex: number,
      totalIndex: number,
    ): Promise<void> {
      try {
        this.$dialog.startLoading();

        const response = await Promise.allSettled([
          this.updateExternalERP(register, date),
          this.updateInternalCustomerERP(register, date),
          this.upsertInternalErp(register, date),
        ]);

        if (!response.every(({ status }) => status === 'fulfilled')) {
          throw new Error();
        }

        const tableName = PredictedFlowHelper.getTableBasedOnNcc(register, Boolean(this.$session.get('use_ncc')));

        await this.accountsLogRepository.createLog(
          this.companyGroupId,
          {
            type: tableName === 'account_receivable' ? 'receivable' : 'payable',
            company_id: register.itemData!.companyId,
            action: AccountActionsEnum.CHANGE_DATE,
            content: 'Data atualizada com sucesso.',
            id_customer: register.itemData!.idCustomer,
          },
        );

        this.$notification.success('Data alterada com sucesso!');

        this.$store.commit('predicted_flow/triggerEventSend', {
          action: ActionsWithoutReloadEnum.change_date,
          itemIndex,
          data: {
            value: date,
            totalIndex,
          },
        });
      } catch (error) {
        this.$notification.error('Houve um problema ao alterar a data.');
      } finally {
        this.$dialog.stopLoading();
      }
    },
    async updateExternalERP(
      register: IPredictedFlowGrouperList,
      date: string,
    ): Promise<void> {
      const tableName = PredictedFlowHelper.getTableBasedOnNcc(register, Boolean(this.$session.get('use_ncc')));

      await this.erpRepository.updateExternalERP<IChangeDateExternalERPRequestParams>(
        this.companyGroupId,
        register.itemData?.companyId!,
        {
          id: register.itemData?.idCustomer!,
          event: ERPEventsEnum.CASH_FLOW_DATE,
          data: {
            cash_flow: date.replaceAll('-', ''),
            type: tableName,
          },
        },
      );
    },
    async updateInternalCustomerERP(
      register: IPredictedFlowGrouperList,
      date: string,
    ): Promise<void> {
      const fieldBasedOnTable = register.grouperType === CashFlowTablesEnum.ACCOUNTS_RECEIVABLE
          ? 'e1_zdtflux'
          : 'e2_zdtflux';

      await this.erpRepository.updateInternalERP({
        id: register.itemData?.id!,
        field: fieldBasedOnTable,
        identify_values: PredictedFlowHelper.getTableAliasByGrouperType(
          register.grouperType,
        ),
        value: date,
      });
    },
    async upsertInternalErp(
      register: IPredictedFlowGrouperList,
      date: string,
    ): Promise<void> {
      await this.erpRepository.saveDataInternalTable({
        _identifyValue: PredictedFlowHelper.getTableAliasByGrouperType(
          register.grouperType,
        ),
        _field: 'data',
        _date: register.itemData?.date!,
        _keyId: register.itemData?.keyId!,
        _data: date,
      });
    },
  },
});
